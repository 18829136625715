import { List, Select, Tag, Tooltip } from 'antd';
import { array, bool, func, object, string } from 'prop-types';
import { useEffect, useMemo, useState } from 'react';

import { IS_CAMPER } from 'utils/constants/system';
import { LOW_STOCK_QUANTITY, JUNCTIONS_TOE_CAPS_SKU } from 'utils/constants/products';
import { useRouter } from 'next/router';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { getMarket, getRealTarget, nnormalSizeMapCountries, nnormalSizesMap } from 'utils/helpers';
import { BellSVG } from 'components/iconsSVG/bell';
import { useI18n } from 'context/i18n';

import styles from './style.module.css';

const { CheckableTag } = Tag;

// on third string

const renderSize = (useHalf, size) => {
  if (!useHalf) {
    return size.label;
  }
  const half = size.label.split(',');
  return (
    <>
      {half[0]}
      {half.length > 1 && <sup>{half[1]}</sup>}
    </>
  );
};

function AvailableTag({ selectedSize, size, showLimitedSizeStyle, useHalf = false, handleMouseOverSize, handleMouseOutSize, handleSelectSize, code }) {
  const isLowStock = size.quantity === LOW_STOCK_QUANTITY;

  return (
    <>
      {!isLowStock || !showLimitedSizeStyle ?
        <CheckableTag
          className={
            selectedSize !== size.value ?
              `${styles.sizeTag} ${showLimitedSizeStyle && size.quantity === LOW_STOCK_QUANTITY ? styles.limitedSizeTag : ''}`
            : `${styles.sizeTag} ${styles.sizeTagSelected} ${showLimitedSizeStyle && size.quantity === LOW_STOCK_QUANTITY ? styles.limitedSizeTag : ''}`
          }
          key={size.label}
          checked={selectedSize === size.value}
          onChange={() => handleSelectSize(size.value, size.label)}
          disabled={!size.available}
          onMouseOver={() => handleMouseOverSize(size)}
          onMouseLeave={() => handleMouseOutSize(size)}
        >
          {renderSize(useHalf, size)}
        </CheckableTag>
      : <Tooltip placement="top" title={'Low stock'} overlayClassName={styles.tooltip} arrowPointAtCenter>
          <CheckableTag
            className={
              selectedSize !== size.value ?
                `${styles.sizeTag} ${showLimitedSizeStyle && size.quantity === LOW_STOCK_QUANTITY ? styles.limitedSizeTag : ''}`
              : `${styles.sizeTag} ${styles.sizeTagSelected} ${showLimitedSizeStyle && size.quantity === LOW_STOCK_QUANTITY ? styles.limitedSizeTag : ''}`
            }
            key={size.label}
            checked={selectedSize === size.value}
            onChange={() => handleSelectSize(size.value, size.label)}
            disabled={!size.available}
            onMouseOver={() => handleMouseOverSize(size)}
            onMouseLeave={() => handleMouseOutSize(size)}
          >
            {renderSize(useHalf, size)}
          </CheckableTag>
        </Tooltip>
      }
    </>
  );
}

AvailableTag.propTypes = {
  selectedSize: string,
  size: object,
  showLimitedSizeStyle: bool,
  handleMouseOverSize: func,
  handleMouseOutSize: func,
  handleSelectSize: func,
  code: string,
  useHalf: bool,
};

function SizesList({
  sizes = [],
  defaultSelectedSize,
  showLimitedSizeStyle = true,
  locale,
  onMouseOverSize,
  onMouseOutSize,
  onChange,
  isSock = false,
  showNotifyMe = false,
  code = '',
  isCamperOne = false,
  select = false,
  currentCountry,
  productSheet,
  calculateColumns,
}) {
  const { t } = useI18n();
  const router = useRouter();
  const [selectedSize, setSelectedSize] = useState(null);
  const market = getMarket(locale);

  const notifyMe = productSheet?.notifyMe;

  const selectSizes = useMemo(() => {
    if (!sizes) return [];
    const routeTarget = getRealTarget(router.query.target);
    // eslint-disable-next-line no-nested-ternary
    const parsedTarget =
      nnormalSizeMapCountries.includes(market) ? market
      : ['M', 'W'].includes(routeTarget) ? routeTarget
      : 'M';

    return sizes
      .sort((a, b) => Number(a.value) - Number(b.value))
      .map((size) => {
        const hasLimitedSizes = size.quantity > 0 && size.quantity < 2;
        const allUnavailable = sizes.every((s) => s.quantity === 0);
        const isSizeNotAvailable = size.quantity === 0;

        const isNotifymeAll = notifyMe && productSheet?.notifyMeType !== 'SIZE';

        let notAvailableMessage = null;

        if (isSizeNotAvailable || isNotifymeAll) {
          notAvailableMessage = `* ${notifyMe ? t('ficha.producto', 'avisame.size') : t('ficha.producto', 'not.available')}`;
        }

        let label = (
          <span className={styles.optionLabel}>
            <span>{nnormalSizesMap[parsedTarget][size.value] ?? size.label}</span>
            {hasLimitedSizes && !isNotifymeAll ?
              <span className={styles.few}>* {t('ficha.producto', 'size.info.only.few')}</span>
            : null}
            {(!allUnavailable && size.quantity === 0) || (notifyMe && productSheet?.notifyMeType !== 'SIZE') ?
              <span className={styles.few}>{notAvailableMessage}</span>
            : null}
          </span>
        );
        if (IS_CAMPER && currentCountry === 'US') {
          label = (
            <span className={styles.optionLabel}>
              <span>{!JUNCTIONS_TOE_CAPS_SKU.includes(code) ? `${size.label} US / ${size.value} EU` : `${size.value} EU`}</span>
              {hasLimitedSizes && !isNotifymeAll && <span className={styles.few}>* {t('ficha.producto', 'size.info.only.few')}</span>}
              {(!allUnavailable && size.quantity === 0) || (notifyMe && productSheet?.notifyMeType !== 'SIZE') ?
                <span className={styles.few}>{notAvailableMessage}</span>
              : null}
            </span>
          );
        }

        let isSizeDisabled = false;

        if (!productSheet?.notifyMe) {
          isSizeDisabled = isSizeNotAvailable;
        }

        return {
          ...size,
          label,
          disabled: isSizeDisabled,
          available: productSheet?.notifyMe && productSheet?.notifyMeType !== 'SIZE' ? false : size.available,
        };
      });
  }, [sizes, t]);

  const useHalfSize = market === 'JP' || market === 'KR';
  const classNames = [styles.listWrapper];

  if (calculateColumns) {
    let calculatedClass = null;

    if (sizes?.length / 6 < 1) {
      calculatedClass = `template-columns-${sizes?.length}`;
    } else {
      calculatedClass = 'template-columns-6';
    }

    classNames.push(styles.customTemplate, styles[calculatedClass]);
  }

  if (isSock) {
    classNames.push(styles.listWrapperSocks);
  }

  const handleSelectSize = (size, sizeLabel) => {
    setSelectedSize(size);
    if (onChange) onChange(size, sizeLabel);
  };

  const handleMouseOverSize = (size) => {
    if (onMouseOverSize) onMouseOverSize(size);
  };

  const handleMouseOutSize = (size) => {
    if (onMouseOutSize) onMouseOutSize(size);
  };

  const handleNnormalSelectSize = (size, option) => {
    setSelectedSize(() => size);
    onChange?.(size, option.label);
  };

  // In production and integra the pdps are cached, so we need to set the selected size in client. We cannot set it in the useState because its value will be cached in ssr. So we need to handle setting the selected size in the useEffect(client side).
  useEffect(() => {
    const querySize = new URLSearchParams(window.location.search).get('size');

    if (querySize && sizes.some((size) => size.value === querySize && (size.available || productSheet?.notifyMe))) {
      setSelectedSize(querySize);

      return;
    }
    if (defaultSelectedSize && sizes.some((size) => size.value === defaultSelectedSize && (size.available || productSheet?.notifyMe))) {
      setSelectedSize(defaultSelectedSize);
    }
  }, []);

  useEffect(() => {
    if (sizes.some((size) => size.value === defaultSelectedSize && (size.available || productSheet?.notifyMe))) {
      setSelectedSize(defaultSelectedSize);
    }
  }, [defaultSelectedSize]);

  return (
    <>
      {select ?
        <Select
          value={selectedSize}
          onChange={handleNnormalSelectSize}
          className={styles.sizeSelect}
          dropdownClassName={styles.sizeSelectDropdown}
          options={selectSizes.map((s) => ({ ...s, available: undefined }))}
          placeholder={`${t('ficha.producto', 'elije.tu.talla')} (${currentCountry})`}
        />
      : <List
          dataSource={
            productSheet?.notifyMe && productSheet?.notifyMeType !== 'SIZE' ? sizes.map((item) => ({ ...item, available: false, quantity: Infinity })) : sizes
          }
          locale={{ emptyText: 'No availables sizes' }}
          className={classNames.join(' ')}
          renderItem={(size) => (
            <List.Item>
              {size.available || isCamperOne || (!showNotifyMe && size.quantity !== 0) ?
                <AvailableTag
                  selectedSize={selectedSize}
                  size={isCamperOne ? { ...size, quantity: Infinity } : size}
                  handleSelectSize={handleSelectSize}
                  handleMouseOverSize={handleMouseOverSize}
                  handleMouseOutSize={handleMouseOutSize}
                  showLimitedSizeStyle={showLimitedSizeStyle}
                  code={code}
                  useHalf={useHalfSize}
                />
              : <Tooltip
                  placement="top"
                  title={productSheet?.notifyMe ? t('ficha.producto', 'avisame.size', 'NOTIFICARME') : t('ficha.producto', 'not.available', '***NOT AVAILABLE')}
                  overlayClassName={styles.tooltip}
                  arrowPointAtCenter
                >
                  <CheckableTag
                    className={`${styles.sizeTag} ${notifyMe ? styles.notifyMeForSize : styles.sizeNotAvailable} ${selectedSize === size.value ? styles.sizeTagSelected : ''}`}
                    key={size.label}
                    checked={selectedSize === size.value}
                    onChange={notifyMe ? () => handleSelectSize(size.value, size.label) : null}
                  >
                    {selectedSize !== size.value && notifyMe && (
                      <BellSVG className={`${styles.bell} ${productSheet?.camperlab ? 'transparentBackground' : ''}`} backgroundFill={'none'} />
                    )}
                    {renderSize(useHalfSize, size)}
                  </CheckableTag>
                </Tooltip>
              }
            </List.Item>
          )}
        />
      }
    </>
  );
}

SizesList.propTypes = {
  sizes: array,
  defaultSelectedSize: string,
  grid: object,
  showLimitedSizeStyle: bool,
  onMouseOverSize: func,
  onMouseOutSize: func,
  onChange: func,
  isSock: bool,
  showNotifyMe: bool,
  locale: string,
  code: string,
  isCamperOne: bool,
  select: bool,
  sizeGuide: array,
  currentCountry: string,
  productSheet: object,
  calculateColumns: bool,
};

export default SizesList;
