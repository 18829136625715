import { BellSVG } from 'components/iconsSVG/bell';
import { getDomainValue } from 'utils/domain';

type ProductCardSizesProps = {
  template: 'square' | 'default';
  onSizeHover?: (size: string | null) => void;
  baseHref?: string;
  sizes?: {
    available: boolean;
    label: string;
    value: string;
    quantity: number;
    ean: string;
    price: {
      current: number;
      currency: string;
      currencySap: string;
      pricePerSize: boolean;
      currentFormated: string;
      usrHash: boolean;
      adqCampaign: boolean;
    };
  }[];
  notifyMe: boolean;
  notifyMeType: string; // deberian de ser valores especificos.
};

export function ProductCardSizes({ template, onSizeHover, sizes = [], notifyMe, notifyMeType, baseHref }: ProductCardSizesProps) {
  const isNotifyMe = notifyMe === true;
  const isNotifyMeBySize = notifyMeType === 'SIZE';
  const isNofityMePre = notifyMeType === 'PRE';
  const manySizes = sizes.length > 8;

  return (
    <div
      className={`isolate z-10 col-start-1 row-start-1 m-6 grid grid-cols-[repeat(auto-fill,minmax(20px,1fr))] self-end text-sm lab:m-0 lab:flex lab:flex-wrap lab:text-xs ${template === 'square' ? 'mx-6 my-3' : ''} ${getDomainValue(
        {
          camper: manySizes ? 'gap-x-2 gap-y-1.5' : 'gap-x-4 gap-y-2',
          nnormal: '',
          camperlab: 'gap-x-2',
        },
      )}`}
    >
      {sizes.map((size, idx) => {
        const shouldShowBell = getDomainValue({
          camper: (isNotifyMe && !isNotifyMeBySize) || (isNotifyMe && isNotifyMeBySize && size.available === false),
          nnormal: false,
          camperlab: false,
        });
        const isAvailable = size.available && size.quantity && size.quantity >= 1;

        return baseHref ?
            <a
              href={`${baseHref}?size=${size.value}`}
              key={`size-label.${size?.ean || idx}.a`}
              className={`relative block cursor-pointer px-[5%] py-[2%] hover:text-copy hover:underline lab:px-0 lab:py-0 lab:font-bold ${!isAvailable || shouldShowBell ? 'text-disabled' : 'text-copy'} ${getDomainValue(
                {
                  camper: !isAvailable && !shouldShowBell ? "bg-[url('/assets-new/del-line-2.svg')] bg-[center_center] bg-no-repeat" : '',
                  nnormal: 'camper',
                  camperlab: !isAvailable || isNofityMePre ? 'text-disabled line-through' : '',
                },
              )} `}
            >
              {shouldShowBell && <BellSVG className="absolute -right-1.5 -top-0.5 scale-[0.7]" backgroundFill="none" />}

              {size.label}
            </a>
          : <div
              key={`size-label.${size?.ean || idx}.b`}
              className={`relative cursor-pointer px-[5%] py-[2%] hover:text-copy hover:underline lab:px-0 lab:py-0 lab:font-bold ${!isAvailable || shouldShowBell ? 'text-disabled' : 'text-copy'} ${getDomainValue(
                {
                  camper: !isAvailable && !shouldShowBell ? "bg-[url('/assets-new/del-line-2.svg')] bg-[center_center] bg-no-repeat" : '',
                  nnormal: 'camper',
                  camperlab: !isAvailable || isNofityMePre ? 'text-disabled line-through' : '',
                },
              )} `}
              onMouseOver={() => onSizeHover(size.value)}
              onMouseLeave={() => onSizeHover(null)}
            >
              {shouldShowBell && <BellSVG className="absolute -right-1.5 -top-0.5 scale-[0.7]" backgroundFill="none" />}
              {size.label}
            </div>;
      })}
    </div>
  );
}
