import { DEFAULT_VALUES_FOR_GIFTCARD } from './constants/products';
import { TIER_POSITIONS } from './constants/cms';
import { DEFAULT_LIMIT, updateQuerystringParam } from './helpers';
import { type ComponentType } from 'react';
// import { type TierProps } from '@camper/react-web-components/dist/organisms';
// import { type TierBannerProps } from '@nnormal/react-web-components/dist/organisms';

type TierProps = any;
type TierBannerProps = any;

const key = 'last_visited_grid';

export function leftFromView(top: number, bottom: number) {
  const topAbsolute = Math.abs(top);
  const bottomAbsolute = Math.abs(bottom);
  return topAbsolute > bottomAbsolute ? 'top' : 'bottom';
}

// function that checks if div is at the bottom of the page or at the top and returns 'top' or 'bottom'
export function enterToView(elementBounds: DOMRectReadOnly, rootBounds: DOMRectReadOnly) {
  const elementTop = Math.abs(elementBounds.top);
  const elementBottom = Math.abs(elementBounds.bottom);
  const rootTop = Math.abs(rootBounds.top);
  const rootBottom = Math.abs(rootBounds.bottom);
  const topAbsolute = Math.abs(elementTop - rootTop);
  const bottomAbsolute = Math.abs(elementBottom - rootBottom);
  return topAbsolute < bottomAbsolute ? 'top' : 'bottom';
}

type CreatePagesArrayArgs = {
  initialPage: number;
  firstProducts?: any[];
  previousPageProducts?: any[];
  nextPageProducts?: any[];
};
export function createPagesArray({ initialPage, firstProducts, previousPageProducts, nextPageProducts }: CreatePagesArrayArgs) {
  const pagesArray = Array.from({ length: nextPageProducts ? initialPage + 1 : initialPage }, (_, i) => {
    if (i === initialPage - 1 && firstProducts) {
      return firstProducts;
    }
    if (i === initialPage - 2 && previousPageProducts) {
      return previousPageProducts;
    }
    if (i === initialPage && nextPageProducts) {
      return nextPageProducts;
    }
    return [];
  });
  return pagesArray;
}

/**
 *
 * @param {object} options;
 * @param {array} options.products
 * @param {number} options.index
 * @param {array} options.pagesRange
 * @param {array} options.tiersIndexes
 * @param {array} options.tiersProps
 * @param {function} options.TierComponent
 * @returns {object}
 */

type GetPagePropsArgs = {
  products: any[];
  index: number;
  pagesRange: number[][];
  tiersIndexes: number[];
  tiersProps: any[];
  TierComponent: ComponentType<TierProps> | ComponentType<TierBannerProps>; // react component ;
};
export function getPageProps({ products, index, pagesRange, tiersIndexes, tiersProps, TierComponent }: GetPagePropsArgs) {
  const page = Number(index + 1);
  const pageMin = pagesRange[index][0];
  const pageMax = pagesRange[index][1];

  const tiersInRange = tiersIndexes.filter((i) => i >= pageMin && i <= pageMax);
  // Si la pagina tiene menos de 40 productos y hay un tier en ese rango hay que meter el primero en la pagina
  if (pageMax - pageMin < DEFAULT_LIMIT && tiersIndexes.some((i) => i > pageMax && i >= pageMin && i <= pageMin + DEFAULT_LIMIT)) {
    // como el tiersIndexes esta ya ordenado con encontrar el primero que este en el rango ya es suficiente
    const nextTier = tiersIndexes.find((i) => i > pageMax && i >= pageMin && i <= pageMin + DEFAULT_LIMIT);
    if (nextTier) {
      tiersInRange.push(nextTier);
    }
  }

  if (!products) return null;
  let placeHolderArray = null;
  if (Array.isArray(products) && !products.length) {
    placeHolderArray = DEFAULT_LIMIT;
    if (tiersInRange.length > 0) {
      placeHolderArray = placeHolderArray + tiersInRange.length;
    }
  }
  if (tiersInRange.length > 0 && products.length > 0) {
    tiersInRange.forEach((tIndex) => {
      const tierToUse = tiersProps[TIER_POSITIONS.GRID].find((tier) => tier.positionInGrid.includes(tIndex));
      if (!tierToUse) return;
      const { numColumns, numColumnsTablet, numColumnsMobile } = tierToUse;
      let tierSize: {
        lg?: number;
        md?: number;
        xs?: number;
      } = {};
      if (!Number.isNaN(Number(numColumns))) tierSize.lg = 6 * numColumns;
      if (!Number.isNaN(Number(numColumnsTablet))) tierSize.md = 8 * numColumnsTablet;
      if (!Number.isNaN(Number(numColumnsMobile))) tierSize.xs = 12 * numColumnsMobile;
      if (Object.keys(tierSize).length === 0) tierSize = undefined;
      const tierGrid = {
        isTier: true,
        isPattersonTier: true,
        tierSize,
        renderTier: <TierComponent {...tierToUse} positionInGrid={tIndex} />,
      };
      const fitsInGrid = tIndex <= pageMax;
      const pageOffset = -2 + page;

      const indexToInsert = tIndex - pageMin + pageOffset;

      // si el tier esta en el rango de productos de la pagina lo metemos en la posicion que le corresponde
      // si el index es mayor que el numero de productos de la pagina y el ultimo elemento de la pagina no es un tier lo metemos al final
      if (fitsInGrid) {
        products = [...products.slice(0, indexToInsert), tierGrid, ...products.slice(indexToInsert)];
      } else if (!products[products.length - 1]?.isTier) {
        products = [...products, tierGrid];
      }
    });
  }
  return { placeHolderArray, products };
}

export function saveLastViewedProduct(id: string) {
  if (typeof window === 'undefined') return;
  const url = window.location.href;
  // value is the window
  // save full url without querystring
  sessionStorage.setItem(key, JSON.stringify({ url: url.toString(), id }));
}

export function findPageAndScroll() {
  const pageInSearchParams = new URLSearchParams(window.location.search).get('page');
  if (!pageInSearchParams) return;
  const pageValue = Number(pageInSearchParams);
  if (Number.isNaN(pageValue)) return;
  // el pageValue es 2 porque la pagina en la url es el index + 1 y queremos ir al div que esta al final de la pagina anterior
  const previusPageBottomDiv = document.querySelector(`[data-bottom="${pageValue - 2}"]`);
  if (previusPageBottomDiv) {
    previusPageBottomDiv.scrollIntoView({ behavior: 'instant', block: 'start' });
  }
}

export function findLastProductAndScroll() {
  if (typeof window === 'undefined') return;
  const lastGrid = sessionStorage.getItem(key);
  if (lastGrid) {
    const parsedLastGrid = JSON.parse(lastGrid) as { url: string; id: string };
    const url = window.location.href.split('?')[0];
    if (url === parsedLastGrid.url.split('?')[0]) {
      const node = document.querySelector(`[data-product-id="${parsedLastGrid.id}"]`);
      if (node) {
        node.scrollIntoView({ behavior: 'instant', block: 'center' });
        return;
      }
    }
  }
  findPageAndScroll();
}

export function updateUrlWithFilters(search: Record<string, any>, sort: string) {
  let querystring = '?';
  if (Object.keys(search).length > 0) {
    const filtersQuerystring = Object.keys(search).map((filterKey) => `${filterKey}=${encodeURI(search[filterKey])}`);
    querystring = `${querystring}${filtersQuerystring.join('&')}`;
  }
  updateQuerystringParam(querystring, 'sort', sort, DEFAULT_VALUES_FOR_GIFTCARD);
}
