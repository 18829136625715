import { useEffect, useRef } from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { useI18n } from 'context/i18n';

import ProductsGrid from 'components/productsGrid';
import ProductsSlider from 'components/productsSlider';

import { parseProductsEcommerceGA4 } from 'utils/gtmUtils';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { getDomainValue } from 'utils/domain';

import styles from './style.module.css';

const ProductsRow = ({
  locale,
  title,
  source,
  slider = false,
  currentList = '',
  camperlab = false,
  withHover,
  fixedGrid = true,
  slidesToShow = undefined,
  forcedSettings,
  withPlaceholder,
  loading = 'lazy',
  squareImg = false,
  addToBagButton = false,
  withLabel = true,
  goToStartOnChange = false,
}) => {
  const { profileData } = useI18n();
  const initDataLayer = useRef(false);

  useEffect(() => {
    // metemos un view_item_list siempre que se monte products row
    try {
      if (source.length > 0 && initDataLayer.current === false) {
        const [firstProduct] = source;
        const currency = profileData?.currencySap ?? firstProduct?.prices?.currency;

        const eventLayerGA4 = {
          event: 'view_item_list',
          nonInteraction: '1',
          ecommerce: {
            currency: profileData?.currencySap || currency,
            items: parseProductsEcommerceGA4(source, currentList, currentList, 0),
          },
        };

        dataLayerHandleEvent(eventLayerGA4);
        initDataLayer.current = true;
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  if (source.length > 0) {
    if (slider) {
      return (
        <>
          {title && (
            <h2
              className={`${styles.title} lab:mx-2.5 lab:mb-2.5 lab:mt-[100px] lab:text-sm lab:uppercase lab:lg:text-xs`}
              data-trackingga4={`${currentList?.toLowerCase()}|slider`}
            >
              {title}
            </h2>
          )}
          <ProductsSlider
            locale={locale}
            products={source}
            currentList={currentList}
            withLabel={withLabel}
            camperlab={camperlab}
            fixedGrid={fixedGrid}
            slidesToShow={slidesToShow}
            forcedSettings={forcedSettings}
            withPlaceholder={withPlaceholder}
            loading={loading}
            withHover={squareImg === true ? false : withHover}
            squareImg={squareImg}
            addToBagButton={addToBagButton}
            goToStartOnChange={goToStartOnChange}
          />
        </>
      );
    }
    return (
      <div className={styles.sectionRowGridWrapper}>
        {title && <h2 className={`${styles.title} lab:mx-2.5 lab:mb-2.5 lab:mt-[100px] lab:text-sm lab:uppercase lab:lg:text-xs`}>{title}</h2>}
        <Row
          className={styles.sectionRowGrid}
          gutter={getDomainValue({
            camper: [0, 0],
            nnormal: [0, 0],
            camperlab: [10, 40],
          })}
        >
          <ProductsGrid
            locale={locale}
            products={source}
            currentList={currentList}
            withLabel={withLabel}
            camperlab={camperlab}
            withHover={squareImg === true ? false : withHover}
            currentListName={title}
            squareImg={squareImg}
            colXS={{ span: getDomainValue({ camper: undefined, nnormal: undefined, camperlab: 12 }) }}
            colMD={{ span: getDomainValue({ camper: undefined, nnormal: undefined, camperlab: 12 }) }}
            colLG={{ span: getDomainValue({ camper: undefined, nnormal: undefined, camperlab: 12 }) }}
            colXL={{ span: getDomainValue({ camper: undefined, nnormal: undefined, camperlab: 6 }) }}
            colXXL={{ span: getDomainValue({ camper: undefined, nnormal: undefined, camperlab: 6 }) }}
          />
        </Row>
      </div>
    );
  }
  return null;
};

ProductsRow.propTypes = {
  locale: PropTypes.string,
  title: PropTypes.string.isRequired,
  source: PropTypes.array.isRequired,
  currentList: PropTypes.string,
  slider: PropTypes.bool,
  camperlab: PropTypes.bool,
  withHover: PropTypes.bool,
  fixedGrid: PropTypes.bool,
  slidesToShow: PropTypes.string,
  forcedSettings: PropTypes.object,
  withPlaceholder: PropTypes.bool,
  loading: PropTypes.any,
  squareImg: PropTypes.bool,
  withLabel: PropTypes.bool,
  addToBagButton: PropTypes.bool,
  goToStartOnChange: PropTypes.bool,
};

export default ProductsRow;
