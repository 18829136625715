import { Fragment, useState, useEffect, type CSSProperties } from 'react';
import { Col, Skeleton, Modal, Button, Spin, Dropdown, Menu } from 'antd';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { useUser } from 'context/user';
import { useI18n } from 'context/i18n';

import SizesList from 'components/sizesList';
import ProductsModal from 'components/productsModal';

import { parseProductClickEcommerce, parseProductClickEcommerceGA4 } from 'utils/gtmUtils';
import {
  getRealID,
  handleStorage,
  COOKIES,
  getMarket,
  getLang,
  getCookie,
  eraseCookie,
  setCookie,
  obtainVoucherPrices,
  getFixedPrice,
  getIsMobile,
} from 'utils/helpers';

import { MAX_BAG_LENGTH } from 'utils/constants/products';
import { AUTO_BONDS } from 'utils/constants/cookies';
import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import { STORES_WITH_SUBTITLE } from 'utils/constants/stores';
import { dataLayerHandleEvent, dataLayerCartClick } from 'utils/dataLayers';
import { setBagCookies } from 'utils/bag';

import { addToBag, getProductLive } from 'actions/products';

import { saveLastViewedProduct } from 'utils/grid';
import { getDomainValue } from 'utils/domain';
import { useLocale } from 'hooks/useLocale';
import { ProductCardSizes } from './product-card-sizes';
import { ProductCardMedia } from './product-card-media';
const ProductBrands = dynamic(() => import('components/productPurchaseCol/productBrands').then((mod) => mod.ProductBrands));

import styles from './style.module.css';

export type ProductCardProps = {
  item: Record<string, any>;
  loadingProp: any;
  colXS: Record<string, any>;
  colMD: Record<string, any>;
  colLG: Record<string, any>;
  colXL: Record<string, any>;
  colXXL: Record<string, any>;
  target: string;
  product: string;
  withHover: boolean;
  withLabel: boolean;
  defaultLimit: number;
  id: string;
  name: string;
  conceptName: string;
  prices: Record<string, any>;
  images: Record<string, any>;
  sizes: any[];
  family: string;
  colors: any[];
  url: string;
  sustainabilities: any[];
  market: string;
  isLastProduct: boolean;
  imagesHover: Record<string, any>;
  currentList: string;
  currentListName: string;
  camperlab: boolean;
  newShooting: boolean;
  withSKU: boolean;
  withDatalayerClick: boolean;
  children: any;
  index: number;
  membersOnly: boolean;
  webExclusive: boolean;
  recalculating: boolean;
  hasModal: boolean;
  squareImg: boolean;
  addToBagButton: boolean;
  showPrices: boolean;
  saveLastProduct: boolean;
};

export function ProductCard({
  item = {},
  colXS = { span: 12 },
  colMD = { span: 8 },
  colLG = { span: 8 },
  colXL = { span: 8 },
  colXXL = { span: 6 },
  withHover = true,
  withLabel = false,
  imagesHover = null,
  prices,
  url,
  target,
  sizes,
  images,
  id,
  name,
  colors,
  market,
  currentList,
  camperlab = false,
  newShooting = false,
  withSKU = false,
  conceptName,
  withDatalayerClick = true,
  children,
  index,
  webExclusive = false,
  currentListName,
  membersOnly = true,
  recalculating = false,
  hasModal = false,
  loadingProp = 'lazy',
  squareImg = false,
  addToBagButton = false,
  showPrices = true,
  saveLastProduct = false,
}: ProductCardProps) {
  const { query } = useRouter();
  const { locale } = useLocale();
  const { t, profileData, bagLength, setBagLength, bag, setBag, setPromoLabel, i18nPage, giftCardList } = useI18n();
  const { config } = profileData;
  const showFreeShipping = config?.show_free_shipping || false;
  const showGiftPurchase = config?.show_gift_purchase || false;
  const { shouldRecalculateVouchers, contextFilters, activatedPrevouchers, handleActivatePrevoucher, appliedVoucher, setAppliedVoucher } = useUser();
  const [hoveredSize, setHoveredSize] = useState(null);
  const [isCalculating, setIsCalculating] = useState(recalculating);
  const isGiftCard = giftCardList.includes(id);

  // show colors
  const productFamily = getRealID('product', query.product);
  const realTargetId = getRealID('target', query.target);
  const showColors = getDomainValue({ camper: true, nnormal: false, camperlab: false }) && productFamily !== '6' && colors && colors.length > 1;

  const [currentPriceData, setCurrentPriceData] = useState(prices !== null && prices !== undefined ? prices : {});
  const [voucherPriceText, setVoucherPriceText] = useState(null);
  const [loadingSizesError, setLoadingSizesError] = useState(null);
  const [itemSizes, setItemSizes] = useState(null);
  const [isAddingToBag, setIsAddingToBag] = useState(false);
  const [touristMode, setTouristMode] = useState(false);

  const isMobile = getIsMobile('767.5px');
  const isTablet = getIsMobile('1280px');

  const { previous, discount, currentFormated, previousFormated, installments, discountStr } = currentPriceData;

  if (!url) {
    url = `/${locale}/producto?option=${id}`;
  }
  const paths = url && url.split('/');
  if (paths.length > 4 && paths[3].indexOf('_') > -1) {
    url = url.replace(paths[3], locale);
  }
  // const formatedUrl = url ? `/${url.split(`/${locale}/`)[1]}` : '#';
  const formatedUrl = url ? url.substring(url.indexOf(`/${locale}/`) + 6) : '#';

  const hasSizes = sizes && sizes.length > 0;
  const outOfStock = (hasSizes && sizes?.every((size) => size.available === false)) || item?.notifyMeType === 'OUT';
  const showSizes = hasSizes && !outOfStock;
  const { storeId } = profileData;

  useEffect(() => {
    const countryPrice = getCookie(COOKIES.COUNTRY_PRICE);

    if (countryPrice && countryPrice !== market) {
      setTouristMode(true);
    }

    const voucherPriceInfo = obtainVoucherPrices(item, t, locale, contextFilters, activatedPrevouchers, handleActivatePrevoucher);
    if (voucherPriceInfo !== null) {
      setIsCalculating(true);
      const { message, info } = voucherPriceInfo;

      if (message) {
        setVoucherPriceText(message);
      } else {
        const infoPriceData = { ...info };
        // caso raro en el buscador
        if (
          infoPriceData.pricePerSize &&
          !infoPriceData?.priceDiscountFromFormatted &&
          !infoPriceData?.priceDiscountToFormatted &&
          infoPriceData?.voucherRangePrices?.priceFromFormatted
        ) {
          infoPriceData.priceDiscountFromFormatted = infoPriceData?.voucherRangePrices?.priceFromFormatted;
          infoPriceData.priceDiscountToFormatted = infoPriceData?.voucherRangePrices?.priceToFormatted;
          infoPriceData.priceFromFormatted = prices?.priceFromFormatted;
          infoPriceData.priceToFormatted = prices?.priceToFormatted;
          if (prices.usePreviousMinAndMaxPrice) {
            infoPriceData.previousFromFormatted = prices?.previousFromFormatted;
            infoPriceData.previousToFormatted = prices?.previousFromFormatted;
          }
        }
        setCurrentPriceData({ ...infoPriceData, discountFromVoucher: true });
        setAppliedVoucher(true);
        setVoucherPriceText(null);
      }

      setTimeout(() => {
        setIsCalculating(false);
      });
    }
  }, [shouldRecalculateVouchers]);

  useEffect(() => {
    if (recalculating !== isCalculating) {
      setIsCalculating(recalculating);
    }
  }, [recalculating]);

  const onClickProduct = () => {
    if (withDatalayerClick) {
      const eventHandler = {
        You_may_also_be_interested: {
          eventAct: 'youMightLikeProductClick',
        },
        Recently_viewed: {
          eventAct: 'recentlyViewedProductClick',
        },
      };

      const handlerParams = eventHandler[currentList];
      dataLayerHandleEvent({
        ...handlerParams,
      });

      const previousSelectItemData = handleStorage('getItem', 'gtmSelectData');
      let newSelectData = null;

      const previousDataParsed = previousSelectItemData === undefined ? {} : JSON.parse(previousSelectItemData);

      newSelectData = {
        ...previousDataParsed,
        [item.id]: parseProductClickEcommerceGA4(item, index + 1)[0],
      };

      handleStorage('setItem', 'gtmSelectData', JSON.stringify(newSelectData));

      const baseEventGA4 = {
        event: 'select_item',
        ecommerce: { items: parseProductClickEcommerceGA4(item, index + 1) },
        isLab: camperlab || false,
      };
      dataLayerHandleEvent({
        ...baseEventGA4,
        ...handlerParams,
      });
    }
  };

  const handleLoadProductSizes = async () => {
    try {
      const response = await getProductLive({
        context: undefined,
        lang: locale,
        target: item.targetId,
        productCode: item.id,
        voucherDiscount: false,
      });
      const productSizes = response?.productSheetLive?.sizes;

      setItemSizes(productSizes);
    } catch (e) {
      console.error(e);
      setLoadingSizesError('Error.');
    }
  };

  const handleAddToBagButton = (mode) => {
    if (isTablet || (!isTablet && mode === 'hover')) {
      if (itemSizes === null) {
        handleLoadProductSizes();
      }
    }
  };

  const addToCartHandler = async ({ payload, cookiesBagId, showJustAdded }) => {
    const cookiesMarket = getCookie(COOKIES.BAG_MARKET);
    const cookiesShipment = getCookie(COOKIES.SHIPMENT);
    const cookiesBonds = getCookie(COOKIES.BONDS);
    const cookiesZip = getCookie(COOKIES.ZIP);
    const cookiesEmployeeId = getCookie(COOKIES.EMPLOYEE_ID);
    const cookiesPackstation = getCookie(COOKIES.PACKSTATION);
    const cookiesCtr = getCookie(COOKIES.IS_CTR);
    if (cookiesMarket !== market) {
      payload.hashOrderId = '';
      if (cookiesBagId) {
        eraseCookie(COOKIES.BAG);
        eraseCookie(COOKIES.BAG_DATE);
      }
      if (cookiesZip) {
        eraseCookie(COOKIES.ZIP);
      }
      if (cookiesShipment) {
        eraseCookie(COOKIES.SHIPMENT);
      }
      if (cookiesMarket !== null && cookiesBonds) {
        eraseCookie(COOKIES.BONDS, COOKIES.DOMAIN);
        eraseCookie(COOKIES.AUTO_BONDS);
      }
      if (cookiesEmployeeId) {
        eraseCookie(COOKIES.EMPLOYEE_ID);
      }
      if (cookiesPackstation) {
        eraseCookie(COOKIES.PACKSTATION);
      }
      if (cookiesCtr) {
        eraseCookie(COOKIES.IS_CTR);
      }
    }
    let updatedPromoLabel = null;
    const result = await addToBag(payload);
    const pricesForAdd = Object.keys(currentPriceData)?.length > 0 ? currentPriceData : item?.prices;
    const productSheet = {
      ...item,
      target: item.targetId,
      size: payload.size,
      prices: { ...pricesForAdd, currency: profileData?.currencySap, currencySap: profileData?.currencySap },
    };
    dataLayerCartClick({ ...payload, productSheet }, result, false, bag);
    if (result.hashOrderId) {
      setBagCookies({ hash: encodeURI(result.hashOrderId), market });

      const { sapActive, extendedBlockSAP, shoppingCart } = result;
      if (!sapActive && !extendedBlockSAP) {
        // can't pay
        window.location.assign(`${window.location.origin}/${locale}/pedido_offline`);
      } else {
        if (extendedBlockSAP) {
          // can pay
          setCookie(COOKIES.SHIPMENT, '01', undefined, COOKIES.DOMAIN);
        }
        if (!showJustAdded) {
          window.location.assign(`${window.location.origin}/${locale}/bolsa`);
        }
        if (showJustAdded) {
          const { price } = shoppingCart[0]; // siempre es una array con un solo item
          const newBag = bag ? JSON.parse(JSON.stringify(bag)) : [];
          setBag([{ ...result.shoppingCart[0], membersOnly: item?.membersOnly }, ...newBag]);
          setBagLength(bagLength + 1);
          const style: CSSProperties = {
            width: !isMobile ? '360px' : '100%',
            maxWidth: !isMobile ? '360px' : '100vw',
            right: '-24px',
          };
          if (isMobile) {
            style.minWidth = 'calc(100vw - 17px)';
          }

          if (showFreeShipping) {
            updatedPromoLabel = result?.freeShippingLabel || null;
          }
          if (showGiftPurchase) {
            updatedPromoLabel = result?.gwplabel || result?.freeShippingLabel || null;
          }
          let priceDiscountString = '';
          if (discountStr || price?.discount) {
            priceDiscountString = discountStr || `-${price.discount}%`;
          }
          const priceDiscountSplitted = priceDiscountString?.split('-');
          if (priceDiscountSplitted?.length > 2) {
            const priceText = priceDiscountSplitted.pop();
            priceDiscountString = `-${priceText}`;
          }

          setPromoLabel(updatedPromoLabel);
        }
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('No se puede procesar el pedido en este momento');
    }
  };

  const handleClickAddToCartBtn = async (showJustAdded = true, selectedSizeValue, selectedSizeLabel) => {
    /* const isUserIdentified = getCookie(COOKIES.SELLIGENT_USRHASH) || userIdentified || bagHasMembersOnlyProduct || userData !== null; */
    setIsAddingToBag(true);
    if (bagLength >= MAX_BAG_LENGTH) {
      Modal.error({
        content: t('ficha.producto', 'alcanzado.limite.bolsa', 'The product could not be added. You have reached the limit of products in your shopping cart.'),
      });
    } else {
      const cookiesBagId = getCookie(COOKIES.BAG);
      const autoVoucher = getCookie(COOKIES.AUTO_BONDS) || '';
      const voucher = getCookie(COOKIES.BONDS) || '';
      const voucherDiscount = IS_CAMPER && autoVoucher && autoVoucher === AUTO_BONDS.APPLY;
      const pricesForAdd = Object.keys(currentPriceData)?.length > 0 ? currentPriceData : item?.prices;
      const payload: any = {
        id,
        price: pricesForAdd,
        profile: {
          countryId: getMarket(locale),
          languageId: getLang(locale),
        },
        size: selectedSizeValue,
        sizeLabel: selectedSizeLabel,
        gender: target || '',
        thumbnail: item?.imageBag || '', // en ocasiones queda como null y causa un error
      };
      if (voucherDiscount) {
        payload.voucher = voucher;
      }
      if (cookiesBagId) {
        payload.hashOrderId = decodeURI(cookiesBagId);
      }

      await addToCartHandler({
        payload,
        cookiesBagId,
        showJustAdded,
      });
      setIsAddingToBag(false);
    }
  };

  const hasBadge = [undefined, ''].includes(item?.badge) === false;

  /*
    lo hacemos así por el cambio de badge de api
    para la respuesta de unbxd solo mostramos el de camper one y el de target
    ya que no recibimos badge
  */

  let customMessage = null;

  if (withLabel === true) {
    if (hasBadge) {
      customMessage = item.badge;
    }
  }

  const priceRange =
    currentPriceData?.priceDiscountFromFormatted && currentPriceData?.priceDiscountToFormatted ?
      `${currentPriceData.priceDiscountFromFormatted} - ${currentPriceData.priceDiscountToFormatted}`
    : `${currentPriceData?.priceFromFormatted} - ${currentPriceData?.priceToFormatted}`;

  const itemSubtitle = getDomainValue({
    camper: !item.recycled && STORES_WITH_SUBTITLE.includes(storeId) ? item?.subtitleH1 : null,
    camperlab: item?.subtitleH1,
    nnormal: item?.codeFeatures,
  });

  let priceDiscountString = '';
  if (discountStr || discount) {
    priceDiscountString = discountStr || `-${discount}%`;
  }
  const priceDiscountSplitted = priceDiscountString?.split('-');
  if (priceDiscountSplitted?.length > 2) {
    const priceText = priceDiscountSplitted.pop();
    priceDiscountString = `-${priceText}`;
  }
  if (!priceDiscountString?.startsWith('-')) {
    priceDiscountString = `-${priceDiscountString}`;
  }

  const baseSpinner = <Spin spinning className={styles.sizeSpinner} />;
  let SizeContent = <Spin spinning className={styles.sizeSpinner} />;

  if (loadingSizesError) {
    SizeContent = <p>{loadingSizesError}</p>;
  } else if (itemSizes !== null) {
    SizeContent = (
      <SizesList
        sizes={itemSizes}
        onChange={async (size, sizeLabel) => {
          await handleClickAddToCartBtn(true, size, sizeLabel);
        }}
        grid={{
          gutter: 0,
          column: itemSizes?.length ?? 1,
        }}
        locale={locale}
        calculateColumns
      />
    );
  }

  const fixedPrice = getFixedPrice(market, profileData, currentPriceData?.current);
  let ProductElement = 'a';

  if (showPrices === false) {
    ProductElement = 'div';
  }

  const colClassName = getDomainValue({
    camper: 'grid-item overflow-hidden',
    nnormal: 'camper',
    camperlab: 'grid-item group overflow-hidden',
  });

  return (
    <Col
      xs={colXS}
      md={colMD}
      lg={colLG}
      xl={colXL}
      xxl={colXXL}
      onMouseDown={() => saveLastProduct && saveLastViewedProduct(id)}
      onMouseUp={onClickProduct}
      className={colClassName}
    >
      <div className={styles.productCard} data-product-id={id}>
        {/* @ts-ignore */}
        <ProductElement className="block" href={!hoveredSize ? `/${locale}${formatedUrl}` : `/${locale}${formatedUrl}?size=${hoveredSize}`}>
          <div className={newShooting ? styles.gridPhoto : `${styles.gridPhoto} ${styles.oldShooting} oldShooting`}>
            {getDomainValue({
              camper: (
                <div className="specialProductMessage">
                  {customMessage !== null && (
                    <div key={`customMessage-${id}`} className="absolute left-0 top-0 z-10 text-copy lab:left-0 lab:top-2.5">
                      <p className="mb-0 p-5 text-[0.8em] uppercase lab:p-0 lab:text-xs lab:font-bold">{customMessage}</p>
                    </div>
                  )}
                </div>
              ),
              nnormal: 'camper',
            })}
            <ProductCardMedia
              productId={id}
              name={name}
              title={item.subtitleH1}
              template={squareImg ? 'square' : 'default'}
              images={item.images}
              imagesHover={imagesHover}
              withHover={withHover}
              index={index}
              loading={loadingProp}
              className={getDomainValue({ camper: 'group', nnormal: 'group', camperlab: '' })}
              sizes={
                IS_CAMPER && !addToBagButton && showSizes ?
                  <div className="absolute bottom-0 hidden w-full opacity-0 xl:group-hover:inline xl:group-hover:opacity-100">
                    <ProductCardSizes
                      onSizeHover={setHoveredSize}
                      template={squareImg === true ? 'square' : 'default'}
                      sizes={item.sizes}
                      notifyMe={item.notifyMe}
                      notifyMeType={item.notifyMeType}
                    />
                  </div>
                : null
              }
            />
            {IS_NNORMAL && webExclusive ?
              <p className={styles.exclusive}>{t('listado.productos', 'web.exclusive', 'exclusive nnormal.com')}</p>
            : null}
          </div>
        </ProductElement>
        <div className={`${styles.gridContent} gridContent relative lab:mx-0 lab:mt-2.5 lab:flex lab:flex-col lab:gap-2.5`}>
          <div>
            {showColors && i18nPage === 'grid' && (
              <ProductBrands id={id} products={colors} className={`mb-[16px] ${styles.productColors}`} placement={'grid'} camperlab={camperlab} />
            )}
            <p className="name mb-0 text-sm nnormal:font-secondary nnormal:font-bold lab:text-xs lab:font-bold lab:uppercase">
              {name || conceptName}
              {item.belowBadge ? ` - ${item.belowBadge}` : ''}
            </p>
            {itemSubtitle && (IS_NNORMAL || STORES_WITH_SUBTITLE.includes(storeId)) && !item.recycled && (
              <p className="mb-0 text-copy nnormal:my-[7px] nnormal:text-sm camper:mb-2.5 camper:text-[0.6em] camper:text-[var(--color-border-panel)] lab:text-xs lab:font-bold lab:uppercase">
                {itemSubtitle}
              </p>
            )}

            {withSKU && <p className={styles.sku}>{id}</p>}
            {!isGiftCard && showPrices && touristMode === false && (
              <>
                {isCalculating && <Skeleton.Input active size="small" className={styles.recalcPrice} />}
                {!isCalculating && (
                  <div className="lab:flex">
                    <p data-fp={fixedPrice} data-ge-price="data-ge-price" className={styles.price}>
                      {currentPriceData.pricePerSize ? priceRange : currentFormated}
                    </p>
                    {previous !== undefined && (
                      <p className={`${styles.discountText} discountText hidden lab:inline-flex`}>
                        <span
                          className={
                            appliedVoucher && currentPriceData?.discountFromVoucher ? `${styles.discount} ${styles.discountByVoucher}` : `${styles.discount}`
                          }
                        >
                          {priceDiscountString}
                        </span>
                        <s>
                          {currentPriceData.pricePerSize ?
                            `${currentPriceData.previousFromFormatted || currentPriceData.priceFromFormatted} - ${currentPriceData.previousToFormatted || currentPriceData.priceToFormatted}`
                          : previousFormated}
                        </s>
                      </p>
                    )}
                  </div>
                )}
                {isCalculating ?
                  <Skeleton.Input active size="small" className={styles.recalcPrice} />
                : <>
                    {previous !== undefined && (
                      <p className={`${styles.discountText} discountText lab:hidden`}>
                        <s>
                          {currentPriceData.pricePerSize ?
                            `${currentPriceData.previousFromFormatted || currentPriceData.priceFromFormatted} - ${currentPriceData.previousToFormatted || currentPriceData.priceToFormatted}`
                          : previousFormated}
                        </s>
                        <span
                          className={
                            appliedVoucher && currentPriceData?.discountFromVoucher ?
                              `${styles.discount} ${styles.discountByVoucher} green`
                            : `${styles.discount} green`
                          }
                        >
                          {priceDiscountString}
                        </span>
                      </p>
                    )}
                    {previous !== undefined && appliedVoucher && currentPriceData?.discountFromVoucher && voucherPriceText === null && (
                      <p className={styles.voucherApplied}>{currentPriceData.appliedLabel}</p>
                    )}
                    {![null, undefined, ''].includes(voucherPriceText) && (
                      <p className={`${styles.voucherPrice}`} dangerouslySetInnerHTML={{ __html: voucherPriceText }} />
                    )}
                  </>
                }
                {currentPriceData?.pricePerSize && (
                  <p className={styles.pricePerSize}>{t('listado.productos', 'final.price.acording', 'Final price acording to size')}</p>
                )}
                {installments && <p className={styles.installments}>{`${t('listado.productos', 'plazos.pago')} ${installments}`}</p>}
              </>
            )}
          </div>
          {getDomainValue({
            camperlab: (
              <div
                id="product-card-lab-sizes"
                key="sizes"
                className="pointer-events-none h-7 bg-neutral opacity-0 transition-opacity duration-200 ease-linear group-hover:pointer-events-auto group-hover:opacity-100"
              >
                <ProductCardSizes
                  onSizeHover={setHoveredSize}
                  template={squareImg === true ? 'square' : 'default'}
                  baseHref={`/${locale}${formatedUrl}`}
                  sizes={item.sizes}
                  notifyMe={item.notifyMe}
                  notifyMeType={item.notifyMeType}
                />
              </div>
            ),
          })}
        </div>
        {getDomainValue({
          camper: (
            <div className={`${styles.itemInfo} itemInfo lab:mt-0`}>
              {hasModal && <ProductsModal.Button productSheet={item} origin="grid" realTarget={realTargetId} />}
            </div>
          ),
          nnormal: 'camper',
          camperlab: null,
        })}
        {addToBagButton && (
          <>
            {item?.sizeType !== undefined ?
              //@ts-ignore
              <Dropdown
                trigger={[isTablet ? 'click' : 'hover']}
                align={{ overflow: { adjustX: isMobile, adjustY: false } }}
                overlay={<Menu className={styles.sizesMenu}>{isAddingToBag ? baseSpinner : SizeContent}</Menu>}
                placement="topCenter"
                arrow={true}
                overlayClassName={styles.sizesDropdown}
              >
                <Button onMouseEnter={() => handleAddToBagButton('hover')} onClick={handleAddToBagButton} className={styles.addToBagRecommended}>
                  {t('ficha.producto', 'add.to.bag')}
                </Button>
              </Dropdown>
            : <>
                {isAddingToBag ?
                  baseSpinner
                : <Button onClick={() => handleClickAddToCartBtn(true, ' ', ' ')} className={styles.addToBagRecommended}>
                    {t('ficha.producto', 'add.to.bag')}
                  </Button>
                }
              </>
            }
          </>
        )}
      </div>
      {children}
    </Col>
  );
}
