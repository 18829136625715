import type { ReactNode } from 'react';

import { ProgressiveBlurredImg } from 'components/base';
import { IMAGE_SIZE_MAPPER } from 'utils/constants/ux';

import styles from './style.module.css';
import { isBlank } from 'utils/StringUtils';

type ProductCardMediaProps = {
  productId: string;
  name: string;
  title: string;
  template: 'square' | 'default';
  images: {
    sources: {
      type?: string;
      main?: string;
      hover?: string;
      altText?: string;
      altTextHover?: string;
      isVideo?: boolean;
      sources?: string;
      poster?: string;
    }[];
    full?: string | { main: string; hover: string };
    lg?: string | { main: string; hover: string };
    md?: string | { main: string; hover: string };
    xs?: string | { main: string; hover: string };
  };
  imagesHover?: {
    xs?: string;
    md?: string;
    lg?: string;
    full?: string;
  };
  withHover?: boolean;
  index?: number;
  loading?: 'lazy' | 'eager';
  sizes?: ReactNode;
  className?: string;
};

export function ProductCardMedia({
  productId,
  index,
  title,
  name,
  loading,
  template,
  images,
  imagesHover,
  withHover = true,
  sizes,
  className = '',
}: ProductCardMediaProps) {
  const srcSetHover: {
    xs?: string;
    md?: string;
    lg?: string;
    full?: string;
  } = {};

  const isVideo = images.sources && images.sources.length === 1 && images.sources[0].isVideo;
  let videoSource = null;

  const alt = images?.sources?.length && images?.sources[0]?.altText ? images?.sources[0]?.altText : `${productId} - ${name}${title ? ` - ${title}` : ''}`;

  const altHover =
    images?.sources?.length && images?.sources[0]?.altTextHover ? images?.sources[0]?.altTextHover : `${productId} - ${name}${title ? ` - ${title}` : ''}`;

  if (isVideo) {
    [videoSource] = images.sources;
  }

  if (withHover) {
    if (imagesHover) {
      srcSetHover.xs = imagesHover.xs;
      srcSetHover.md = imagesHover.md;
      srcSetHover.lg = imagesHover.lg;
      srcSetHover.full = imagesHover.full;
    } else if (Array.isArray(images.sources)) {
      images.sources.forEach((source) => {
        srcSetHover[source.type] = source.hover ?? source.main;
      });
    }
  }
  const highQualitySrc =
    Array.isArray(images.sources) ?
      images.sources
    : Object.entries(images).reduce((acc, [key, value]) => {
        if (['full', 'lg', 'md', 'xs'].includes(key) && typeof value !== 'string' && 'main' in value) {
          acc.push({
            type: key,
            main: value.main,
            hover: value.hover,
          });
        }
        if (['full', 'lg', 'md', 'xs'].includes(key) && typeof value === 'string') {
          acc.push({
            type: key,
            main: value,
          });
        }
        return acc;
      }, [] as any);

  return (
    <div className={`${styles.gridImages} gridImages relative overflow-hidden ${className}`} data-square={template === 'square'}>
      {isVideo ?
        <video
          className={styles.gridVideo}
          autoPlay
          loop
          title={`Video of ${productId}`}
          muted
          playsInline
          poster={videoSource.poster}
          dangerouslySetInnerHTML={{ __html: videoSource.sources }}
        ></video>
      : <ProgressiveBlurredImg highQualitySrc={highQualitySrc} initLoaded={true} alt={alt} index={index} loading={loading} pictureClassName="lab:bg-neutral" />}
      {withHover && (
        <picture className={``}>
          {/* TO DO: En el futuro, hacer lo mismo que hacemos en el source de webp para image/avif */}
          {Object.keys(srcSetHover)
            .filter((hoverItem) => hoverItem !== 'xs')
            .map((srcKey) => (
              <source
                key={`${productId}-${srcKey}`}
                type="image/avif"
                srcSet={`${srcSetHover[srcKey]?.replace(/\.jpg|\.png/, '.avif')}`}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />
            ))}
          {Object.keys(srcSetHover)
            .filter((hoverItem) => hoverItem !== 'xs')
            .map((srcKey) => (
              <source
                key={`${productId}-${srcKey}`}
                type="image/webp"
                srcSet={`${srcSetHover[srcKey]?.replace(/\.jpg|\.png/, '.webp')}`}
                media={IMAGE_SIZE_MAPPER[srcKey]}
              />
            ))}
          {Object.keys(srcSetHover)
            .filter((hoverItem) => hoverItem !== 'xs')
            .map((srcKey) => (
              <source key={`${productId}-${srcKey}`} type="image/jpg" srcSet={`${srcSetHover[srcKey]}`} media={IMAGE_SIZE_MAPPER[srcKey]} />
            ))}
          <img
            src={srcSetHover.md}
            alt={altHover}
            className={`${styles.imageHover} group-hover:visible lab:bg-neutral max-xl:camper:group-hover:invisible`}
            loading="eager"
          />
        </picture>
      )}
      {sizes}
    </div>
  );
}
